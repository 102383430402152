<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header justify-content-between">
					<div class="input-group input-group-flush d-flex flex-row-reverse">
						<input
							v-model.trim="search"
							class="form-control list-search"
							type="search"
							placeholder="Search"
							@keyup.enter.prevent="fetchValentineCampaigns(true)"
							/>
						<span class="input-group-text border-0">
							<i
								class="fe fe-search pointer"
								@click.prevent="fetchValentineCampaigns(true)"
								></i>
						</span>
					</div>
				</div>

				<b-table
					striped
					hover
					selectable
					show-empty
					responsive
					:items="filteredValentineCampaigns"
					:fields="fields"
					:busy="fetchingValentineCampaigns"
					@row-clicked="viewCampaign"
					>
					<template #table-busy>
						<div
							class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
							>
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>

					<template #empty>
						<p class="text-center text-secondary">
							{{
								search
									? `No records found for search value: "${search}"`
									: 'No records available'
							}}
						</p>
					</template>

					<template #cell(sn)="data">
						{{ data.index + 1 }}
					</template>

					<template #cell(name)="data">
						{{ data.item.name }}
					</template>

					<template #cell(start_date)="data">
						{{ data.item.start_date | date('dd/MM/yyyy') }}
					</template>

					<template #cell(end_date)="data">
						{{ data.item.end_date | date('dd/MM/yyyy') }}
					</template>
					<template #cell(created_at)="data">
						{{ data.item.created_at | date('dd/MM/yyyy') }}
					</template>
					<template #cell(status)="data">
						<span
							class="sh-page-link badge text-white"
							:class="data.item.is_active == 1 ? 'bg-success' : 'bg-danger'"
							>{{ data.item.is_active == 1 ? 'Active' : 'Inactive' }}</span
							>
					</template>
				</b-table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'ValentineCampaignList',
  data () {
    return {
      fields: [
        {
          key: 'sn',
          label: 'S/N'
        },
        {
          key: 'name',
          label: 'NAME'
        },
        {
          key: 'start_date',
          label: 'START DATE'
        },
        {
          key: 'end_date',
          label: 'END DATE'
        },
        {
          key: 'created_at',
          label: 'CREATED AT'
        },
        {
          key: 'status',
          label: 'STATUS'
        }
      ],
      perPage: 20,
      currentPage: 1,
      totalRecords: 0,
      campaigns: [],
      fetchingValentineCampaigns: true,
      search: '',
      filter: {
        range: []
      }
    }
  },
  created () {
    this.fetchValentineCampaigns()
  },
  methods: {
    fetchValentineCampaigns () {
      this.fetchingValentineCampaigns = true
      const url = '/v1/campaigns'
      this.axios
        .get(url)
        .then((res) => {
          this.campaigns = res.data
          return this.campaigns
        })
        .finally(() => (this.fetchingValentineCampaigns = false))
    },

    viewCampaign (campaign) {
      this.$router.push({
        name: 'CampaignDetails',
        params: { campaignId: campaign.id }
      })
    }
  },
  computed: {
    filteredValentineCampaigns () {
      const search = this.search.toLowerCase()
      const campaigns = this.campaigns.filter((campaign) => {
        return campaign?.name?.toLowerCase().includes(search)
      })
      return campaigns
    }
  }
}
</script>
